import { addDays, endOfToday, startOfToday } from 'date-fns';
import * as qs from 'qs';
import { ElementRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isRouteErrorResponse,
  useOutlet,
  useParams,
  useRouteError,
} from 'react-router-dom';
import { useQuery } from 'urql';
import { RootBoundary } from '~/RootBoundary';
import { Can } from '~/components/Can';
import { Outlet } from '~/components/Split';
import { BulkEditView } from '~/components/job/BulkEditView';
import { JobList } from '~/components/job/JobList/JobList';
import { Unscheduled } from '~/components/job/JobList/Unscheduled';
import { ErrorMessage } from '~/components/ui/Error';
import { FilterGroupSearch } from '~/components/ui/FilterGroupSearch';
import { Loading } from '~/components/ui/Loading';
import { MobileAddButton } from '~/components/ui/buttons/MobileAddButton';
import { Checkbox, CheckboxState } from '~/components/ui/nucleus/Checkbox';
import { SortBy, SortedBy, sortJobs } from '~/components/ui/sortBy';
import { graphql } from '~/gql';
import {
  CreateJobsMutation,
  JobStatus,
  ListJobsQuery,
  ListJobsQueryVariables,
} from '~/gql/graphql';
import { remove } from '~/helpers/array';
import parseFilters from '~/helpers/parseFilters';
import { useLocalStorage } from '~/hooks';
import { useBreakpoint } from '~/hooks/useBreakpoint';
import { useSearch } from '~/hooks/useSearch';
import { JobsNav } from '~/layouts/nav/JobsNav';
import { NotFound } from './NotFound';
import { parseAttributeFilters } from './calendar._index';
import { extractAttributes, useSites } from './resources/sites';

const ListJobsDocument = graphql(`
  query ListJobs(
    $scheduled: String
    $dateAfter: DateTime
    $dateBefore: DateTime
    $status: [JobStatus!]
    $locationName: [String!]
    $locationOwnerId: [ID!]
    $locationAttributes: [AttributeInput!]
    $assignee: [ID!]
    $included: [ID!]
    $ofType: String
    $tags: [ID!]
  ) {
    assignees: contacts {
      id
      name
    }
    tags {
      edges {
        node {
          id
          name
          category
          description
          colour
        }
      }
    }
    jobBoard(
      scheduled: $scheduled
      scheduleFrom: $dateAfter
      scheduleTo: $dateBefore
      status: $status
      locationName: $locationName
      locationOwnerId: $locationOwnerId
      locationAttributes: $locationAttributes
      assignee: $assignee
      included: $included
      ofType: $ofType
      tags: $tags
    ) {
      ... on JobCalendarEvent {
        __typename
        id
        hidden
        # some fields are needed for grouping before unmasking:
        summary
        start
        end
        ...JobCalendarEventFields
      }
      ... on Job {
        __typename
        id
        status
        name
        createdAt
        scheduleStart
        scheduleEnd
        scheduledStartStrict
        scheduledStartDate
        scheduledStartTime
        scheduledEndStrict
        scheduledEndDate
        scheduledEndTime
        completedAt
        timeZone
        image
        jobTags {
          id
          entityType
          name
          category
          description
          colour
        }
        location {
          __typename
          id
          name
          ... on Site {
            licensor {
              id
              name
            }
          }
        }
        assignees {
          id
          name
          image
        }
        occupied {
          id
          checkIn: start
          checkOut: end
        }
        prevCheckOut {
          id
          checkIn: start
          checkOut: end
        }
        nextCheckIn {
          id
          checkIn: start
          checkOut: end
        }
      }
    }
  }
`);

//prettier-ignore
const err = () => { throw new Error(); };
const typeIsJob = (job: TResult) => (job.__typename === 'Job' ? job : err());
//prettier-ignore
const typeIsCal = (cal: TResult) => cal.__typename === 'JobCalendarEvent' ? cal : err();

type TResult = ListJobsQuery['jobBoard'][0];
export type TJob = ReturnType<typeof typeIsJob>;
export type TJobCalendarEvent = ReturnType<typeof typeIsCal>;

export type ViewMode = 'scheduled' | 'unscheduled' | 'completed';

type TScrollToJob = ElementRef<typeof JobList>;

const SEARCH_OPTIONS = {
  keys: ['name', 'location.name', 'tags'],
};

export function JobsLayout() {
  const { t } = useTranslation(['translation', 'job']);
  const sites = useSites();
  const { view, jobId } = useParams();
  const storage = useLocalStorage({ session: true });
  const [variables, setVariables] = useState<ListJobsQueryVariables>({
    scheduled: view,
  });
  const { isTablet: isSmall, isMobile } = useBreakpoint();
  const jobListRef = useRef<TScrollToJob>(null);

  useEffect(() => {
    setVariables((v) => ({ ...v, scheduled: view }));
  }, [view]);

  function filterN() {
    const filters = Object.values(variables).filter(
      (value) => Array.isArray(value) && value.length > 0
    );
    return filters.length ? true : false;
  }

  const hasFilters = filterN();
  const [result] = useQuery({
    query: ListJobsDocument,
    pause: Object.keys(variables).length <= 1, // only fetch when there are filter variables
    requestPolicy: 'cache-and-network',
    variables: {
      ...variables,
    },
  });

  const { data, fetching, error } = result;
  const { results: jobs, search } = useSearch(data?.jobBoard, SEARCH_OPTIONS);

  const [scrollTo, setScrollTo] = useState('');
  useEffect(() => {
    if (fetching) return;
    //! FIXME duplicated in JobsList
    if (scrollTo) {
      document
        .querySelector(`a[href="/jobs/${scrollTo}"]`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [fetching, scrollTo]);

  const scrollToFirst = (data?: CreateJobsMutation) => {
    if (data?.createJobs[0]) {
      setScrollTo(data.createJobs[0].id);
    }
  };

  const [searchValue, setSearchValue] = useState<string>('');
  const [filtersValue, setFiltersValue] = useState<URLSearchParams>(
    loadInitialFilters(storage, view)
  );
  const [sortBy, setSortBy] = useState(
    () => (storage.get('job.sortBy') as SortedBy) || SortedBy.TIME
  );

  useEffect(() => {
    storage.set('form.filter.job-list', filtersValue.toString());
  }, [view, filtersValue]);

  const filteredJobs = jobs?.filter((job) => {
    const bookingFilters = parseFilters(filtersValue).bookingType;
    return (
      job.__typename !== 'JobCalendarEvent' ||
      bookingFilters?.includes(job.summary)
    );
  });

  const [selected, setSelected] = useState<string[]>([]);
  const toggleSelected = (id: string, shift: boolean, single = false) => {
    if (single) return setSelected([id]);

    setSelected((prev) => {
      const idx = prev.findIndex((s) => s === id);

      // Shift key is held...
      if (shift && prev.length > 0) {
        const a = data?.jobBoard.findIndex(
          (job) => job.__typename === 'Job' && job.id === id
        );
        const b = data?.jobBoard.findIndex(
          (job) => job.__typename === 'Job' && job.id === prev[prev.length - 1]
        );

        if (a !== undefined && b !== undefined) {
          const from = Math.min(a, b);
          const to = Math.max(a, b);

          return [...Array(1 + to - from)]
            .map((_, i) => {
              const item = data?.jobBoard[i + from];
              return item?.__typename === 'Job' ? item.id : undefined;
            })
            .filter(Boolean);
        }
      }

      return idx > -1 ? remove(prev, idx) : [...prev, id];
    });
  };
  const outlet = useOutlet();
  const selectionProps = { selected, toggleSelected };
  useEffect(() => setSelected([]), [view]);
  useEffect(() => {
    if (!outlet) setSelected([]);
  }, [outlet]);

  if (error) {
    // Clear saved filters to avoid a possible catch-22
    storage.set('form.filter.job-list', 'null');

    return (
      <ErrorMessage
        message={error.networkError ? 'Network error' : error.message}
      />
    );
  }

  const exportFilters = qs.stringify(variables);
  const attributeOptions = extractAttributes(sites);

  const handleCheckboxChange = (checkboxState: CheckboxState) => {
    const jobsIds = filteredJobs?.map((jobs) => jobs.id) || [];
    setSelected(checkboxState === CheckboxState.CHECKED ? jobsIds : []);
  };

  const sortedJobs = sortJobs(sortBy, filteredJobs);

  const checkboxState =
    sortedJobs?.length === selected.length
      ? CheckboxState.CHECKED
      : selected.length > 0
      ? CheckboxState.INDETERMINATE
      : CheckboxState.UNCHECKED;

  return (
    <>
      <MobileAddButton />
      <div className='pt-[4.5rem] lg:pt-0'>
        <div className='fixed top-0 z-10 w-full border-b border-grey-20 bg-white lg:static lg:z-auto lg:w-auto'>
          <JobsNav />
        </div>
        <div className='flex w-full flex-wrap items-center border-b border-grey-20 bg-white shadow-sm transition-all duration-300 ease-in-out lg:ml-1 lg:flex-nowrap lg:px-6'>
          {view !== 'completed' && !isMobile && (
            <div className='flex items-center gap-2'>
              <p className='truncate'>Select All</p>
              <div className='flex w-6 items-center justify-center'>
                <Checkbox
                  value={checkboxState}
                  onClick={(_, value) => handleCheckboxChange(value)}
                />
              </div>
            </div>
          )}

          <FilterGroupSearch
            filters={[
              ...(view === 'scheduled'
                ? [
                    {
                      type: 'date' as 'date',
                      name: 'scheduledDate',
                    },
                  ]
                : []),
              ...(view === 'completed'
                ? [
                    {
                      type: 'date' as 'date',
                      name: 'completedDate',
                    },
                  ]
                : []),
              {
                name: 'status',
                label: t('translation:status'),
                options: [
                  { value: JobStatus.Created, label: t('job:Created') },
                  {
                    value: JobStatus.InProgress,
                    label: t('job:InProgress'),
                  },
                  { value: JobStatus.Offered, label: t('job:Offered') },
                  { value: JobStatus.Accepted, label: t('job:Accepted') },
                  { value: JobStatus.Declined, label: t('job:Declined') },
                  { value: JobStatus.Cancelled, label: t('job:Cancelled') },
                  { value: JobStatus.Complete, label: t('job:Complete') },
                ],
                searchable: false,
                type: 'select',
              },
              {
                name: 'locationName',
                label: t('site'),
                options:
                  sites
                    .filter(({ status }) => status === 'Active')
                    .map(({ name }) => ({
                      value: name,
                      label: name,
                    })) ?? [],
                type: 'select',
              },
              {
                name: 'assignee',
                label: t('job:assignee'),
                options:
                  data?.assignees?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? [],
                type: 'select',
              },
              {
                name: 'included',
                label: t('included'),
                options:
                  data?.assignees?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  })) ?? [],
                type: 'select',
              },
              ...(data?.tags && data?.tags.edges.length > 0
                ? [
                    {
                      name: 'tags',
                      label: t('tag_plural'),
                      options: data.tags.edges.map(({ node }) => ({
                        value: node.id,
                        label: node.name,
                      })),
                      type: 'select' as const,
                    },
                  ]
                : []),
              {
                name: 'locationAttributes',
                label: t('attribute_plural'),
                options: attributeOptions,
                type: 'facetedSelect',
              },
              ...(view === 'scheduled'
                ? [
                    {
                      name: 'bookingType',
                      label: t('jobCalendarEvents'),
                      options: [
                        // TODO use translation for labels
                        { value: 'Check-in', label: 'Check-in' },
                        { value: 'Check-out', label: 'Check-out' },
                      ],
                      searchable: false,
                      type: 'select' as const,
                    },
                  ]
                : []),
            ]}
            value={{ searchValue: searchValue, filtersValue: filtersValue }}
            onChange={({ searchValue, filtersValue }) => {
              setSearchValue(searchValue);
              setFiltersValue(filtersValue);

              const parsedFilters = parseFilters(filtersValue);
              const attributeFilters = parseAttributeFilters(
                parsedFilters['locationAttributes']
              );
              const parsedFiltersWithEmpty = {
                status: (parsedFilters.status ?? []) as JobStatus[],
                locationName: parsedFilters.locationName ?? [],
                assignee: parsedFilters.assignee ?? [],
                included: parsedFilters.included ?? [],
                tags: parsedFilters.tags ?? [],
                // locationAttributes: parsedFilters.locationAttributes ?? [].
              };
              const dates =
                view === 'scheduled'
                  ? {
                      // parseFilters() always returns array values so we need to get the first item for single value filters
                      dateAfter: parsedFilters.scheduledDateAfter?.[0],
                      dateBefore: parsedFilters.scheduledDateBefore?.[0],
                    }
                  : view === 'completed'
                  ? {
                      dateAfter: parsedFilters.completedDateAfter?.[0],
                      dateBefore: parsedFilters.completedDateBefore?.[0],
                    }
                  : {};
              setVariables((v) => ({
                ...v,
                ...parsedFiltersWithEmpty,
                ...dates,
                locationAttributes: attributeFilters,
                ofType: '',
              }));
              search(searchValue);
            }}
            placement={'portal'}
          />

          <div className='ml-auto flex'>
            {view === 'scheduled' && (
              <SortBy
                className='mr-7'
                options={[
                  { key: SortedBy.TIME, name: 'Time' },
                  { key: SortedBy.PROPERTY, name: 'Property' },
                  { key: SortedBy.ASSIGNEE, name: 'Assignee' },
                ]}
                value={sortBy}
                onChange={(val) => {
                  setSortBy(val);
                  storage.set('job.sortBy', val);
                }}
              />
            )}

            <div className='hidden items-center gap-4 px-4 py-2.5 md:flex lg:order-last lg:px-0 lg:py-0'>
              <Can do='read' on='feat.export_jobs'>
                <div className='whitespace-nowrap text-right'>
                  <a
                    className='text-brand hover:underline'
                    href={`/api/export-jobs?${exportFilters}&view=${view}&format=csv&t=${new Date().valueOf()}`}
                  >
                    {t('export')}
                  </a>
                </div>
              </Can>
            </div>
          </div>
        </div>
        {/* <FilterBar setScrollToDate={setScrollToDate}>
          <JobFilters
            view={view}
            assignees={data?.assignees}
            tags={data?.jobTags}
            onFiltered={(f) => setVariables((v) => ({ ...v, ...f }))}
            onSearch={search}
          />
        </FilterBar> */}
      </div>
      <div id='jobs-main' className='w-full lg:mt-0 lg:flex'>
        <div className='flex-1 lg:overflow-y-hidden'>
          {fetching || view !== variables.scheduled ? (
            <div className='flex h-48 items-center justify-center'>
              <Loading spinner />
            </div>
          ) : view === 'scheduled' ? (
            <JobList
              view='scheduled'
              jobs={sortedJobs}
              scrollId={jobId}
              hasFilters={hasFilters || searchValue.length > 0}
              isSmall={isSmall}
              ref={jobListRef}
              {...selectionProps}
            />
          ) : view === 'unscheduled' ? (
            <Unscheduled
              jobs={filteredJobs}
              scrollId={jobId}
              hasFilters={hasFilters || searchValue.length > 0}
              isSmall={isSmall}
              {...selectionProps}
            />
          ) : view === 'completed' ? (
            <JobList
              view='completed'
              jobs={filteredJobs}
              scrollId={jobId}
              hasFilters={hasFilters || searchValue.length > 0}
              isSmall={isSmall}
              ref={jobListRef}
            />
          ) : null}
        </div>
        {selected.length > 1 || (selected.length > 0 && !outlet) ? (
          <BulkEditView selected={selected} onClose={() => setSelected([])} />
        ) : (
          <Outlet
            context={{ scrollToJob: () => jobListRef.current?.scrollToJob() }}
            height='lg:h-[calc(100vh-12.5rem)]'
          />
        )}
      </div>
    </>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  return <RootBoundary />;
}

type Storage = ReturnType<typeof useLocalStorage>;

function loadInitialFilters(storage: Storage, view: string | undefined) {
  if (!view) return new URLSearchParams();

  const filters = new URLSearchParams(
    storage.get('form.filter.job-list') || []
  );
  const defaults = new URLSearchParams(defaultFilters());

  defaults.forEach((value, key) => {
    if (!filters.has(key)) {
      filters.set(key, value);
    }
  });

  return filters;
}

function defaultFilters() {
  const today = startOfToday();
  const sevenDaysAgo = addDays(today, -7);
  const fourteenDaysFromToday = addDays(today, 14);

  return [
    ['scheduledDateAfter', sevenDaysAgo.toISOString()],
    ['scheduledDateBefore', fourteenDaysFromToday.toISOString()],
    ['completedDateAfter', sevenDaysAgo.toISOString()],
    ['completedDateBefore', endOfToday().toISOString()],
  ];
}
